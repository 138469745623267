import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import queryString from 'core/libs/query-string';
import { withRouter } from 'core/libs/router';

import Feed from 'core/components/Feed';
import Button from 'core/components/Button';

import bindProps from 'core/components/bindProps';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import { VerticalIndent } from 'site/components/Indents';

import { Card3Type1 } from 'site/cards/Card3';
import Card5 from 'site/cards/Card5';

import { STARS_LIMIT } from 'site/constants';

/**
 * Загружает из апи данные топиков `getTopics()` по slug тега
 *
 * @author Aleksey Dibrivniy <a.dibrivniy@rambler-co.ru>
 */

const Card5Custom = bindProps({ imageMaxWidth: 394 })(Card5);

function TopicsTaggedWithStar(props) {
  const {
    isDesktop,
    rawTopics,
    moreTopics,
    location,
    history,
    topicsPage,
    handlePage,
  } = props;

  const {
    topics: baseTopics,
    meta: { filtered_count: count },
  } = rawTopics;

  const [topicsState, setTopicsState] = useState(baseTopics);

  useEffect(() => {
    const { page } = queryString.parse(location.search);

    if (!!page) {
      history.replace(location.pathname);
    }
  }, [location.pathname, history, location.search]);

  useEffect(() => {
    if (!moreTopics?.length) return;
    setTopicsState(prevTopicsState => ([...prevTopicsState, ...moreTopics]));
  }, [moreTopics]);

  /**
   * При округлении обязательно делаем фолбек на `1` в случае если при расчете
   * будет получен `0` из-за отсутсвия данных иначе мы получем бесконечный
   * редирект из-за срабатывания условия `!checkPageUrlParam(location, pageCount)`.
   */

  const pageCount = Math.ceil(count / STARS_LIMIT) || 1;

  return (
    <Fragment>
      <VerticalIndent />
      <Feed
        content={topicsState}
        interitemSpacing={30}
        card={isDesktop ? Card5Custom : Card3Type1}
        columns={2}
        grid
      />
      <VerticalIndent />
      {topicsState?.length < count - 1 && (
        <Button
          size={isDesktop ? 'large' : 'medium'}
          onClick={handlePage}
          minWidth='100%'
          disabled={topicsPage >= pageCount}
        >
          Показать еще
        </Button>
      )}
    </Fragment>
  );
}

TopicsTaggedWithStar.propTypes = {
  isDesktop: PropTypes.bool,

  rawTopics: PropTypes.shape({
    topics: PropTypes.arrayOf(modelPropTypes(topicAttributes).isRequired),
    meta: PropTypes.shape({
      filtered_count: PropTypes.number,
    }),
  }),

  moreTopics: PropTypes.arrayOf(modelPropTypes(topicAttributes).isRequired),

  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,

  topicsPage: PropTypes.number.isRequired,
  handlePage: PropTypes.func.isRequired,
};

export default withRouter(withBreakpoint(TopicsTaggedWithStar));
