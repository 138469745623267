import useRequest from 'core/hooks/useRequest';
import { rawAuthorsQuery } from 'core/queries/authorsPage';

import AuthorsPage from 'core/components/AuthorsPage';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import SectionTitle from 'site/components/SectionTitle';
import { VerticalIndent } from 'site/components/Indents';
import Boroda from 'site/components/Boroda';
import { InnerSideColumnShort } from 'site/components/InnerSideColumn';


function Authors(routerProps) {
  const { data: rawAuthors, isLoading } = useRequest(rawAuthorsQuery(routerProps));

  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumnShort />}>
        <SectionTitle htmlTag='h1'>Авторы</SectionTitle>
        <VerticalIndent />
        <AuthorsPage rawAuthors={rawAuthors} isLoading={isLoading} />
      </ColumnLayout>
      <VerticalIndent />
      <Boroda />
      <VerticalIndent />
    </PageIndent>
  );
}

export default Authors;
