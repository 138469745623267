import { topicsQuery } from 'core/queries/topics';

export default params => {
  return topicsQuery({
    limit: 12,
    topic_type: 'horoscope',
    sort: 'horoscope',
    plugin: JSON.stringify(
      {
        horoscope: {
          kind: 'general',
          period: 'today',
        },
      }
    ),
    ...params,
    include: params.include || 'all',
  });
};
