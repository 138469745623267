import React from 'react';
import { withRouter } from 'core/libs/router';

import useRequest from 'core/hooks/useRequest';
import { topicsQuery } from 'core/queries/topics';

import { denormalizeData } from 'core/utils/api';

import { dateFormat } from 'core/utils/dates';


export const StarsBirthdayContext = React.createContext({
  starsBirthday: [],
});

function StarsBirthdayProvider({ children, ...routerProps }) {
  const { data: starsBirthday } = useRequest(topicsQuery({
    limit: 10,
    include: 'image,person_meta',
    person_birth_date: dateFormat(new Date(), 'yyyy-MM-dd'),
    ...routerProps,
  }, { select: denormalizeData }));

  return (
    <StarsBirthdayContext.Provider value={{ starsBirthday }}>
      {children}
    </StarsBirthdayContext.Provider>
  );
}

export default withRouter(StarsBirthdayProvider);
