import { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { topicsQuery } from 'core/queries/topics';
import { bebop as bebopApi } from 'core/api/definitions/bebop';

import useRequest from 'core/hooks/useRequest';

import { denormalizeData, filterRequiredParams } from 'core/utils/api';

import queryString from 'core/libs/query-string';

import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';
import Topic from 'core/components/Topic';
import PageLoader from 'core/components/Loader/PageLoader';

import StarTopic from 'site/pages/topic/content/star';

import TopicsTaggedWithStar from 'site/components/TopicsTaggedWithStar';
import Boroda from 'site/components/Boroda';
import TopicFooter from 'site/components/TopicFooter';
import InnerSideColumn from 'site/components/InnerSideColumn';
import { VerticalIndent } from 'site/components/Indents';

import { STARS_LIMIT } from 'site/constants';

import Card5 from 'site/cards/Card5';


const ADDITIONAL_TOPICS_BASE_LIMIT = STARS_LIMIT * 3;

function Star({ match, location, history }) {
  const { preview_token: previewToken } = queryString.parse(location.search);

  const [topicsPage, setTopicsPage] = useState(1);

  const handleAdditionalTopics = useCallback(() => {
    setTopicsPage(topicsPage + 1);
  }, [topicsPage]);

  const additionalTopicsRequest = {
    include: filterRequiredParams([Card5], 'include'),
    fields: filterRequiredParams([Card5], 'fields'),
    tag: match.params.level_2,
  };

  const [
    { data: rawRubric, isLoading: rubricIsLoading },
    { data: starTopics, isLoading: starIsLoading },
    { data: additionalTopics, isLoading: additionalTopicsIsLoading },
  ] = useRequest({
    queries: [
      {
        queryKey: ['bebopApi', 'getRubric', {
          rubric_slug: 'stars',
          root_slug: 'stars',
        }],
        queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
      },
      topicsQuery({
        limit: 1,
        include: 'all',
        topic_type: 'star',
        preview_token: previewToken,
        location,
        match,
        history,
      }),
      {
        queryKey: ['bebopApi', 'getTopics', {
          ...additionalTopicsRequest,
          offset: 0,
          limit: ADDITIONAL_TOPICS_BASE_LIMIT,
          with_filtered_count: 1,
        }],
        queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
        select: data => ({
          topics: denormalizeData(data),
          meta: data.meta,
        }),
      },
    ],
  });

  const { data: moreAdditionalTopics } = useRequest(
    topicsQuery({
      ...additionalTopicsRequest,
      limit: STARS_LIMIT,
      offset: ADDITIONAL_TOPICS_BASE_LIMIT + (topicsPage * STARS_LIMIT),
      excluded_ids: starTopics && starTopics[0].id,
      location,
      match,
      history,
    }, {
      enabled: !!starTopics && topicsPage > 1,
    })
  );

  if (additionalTopicsIsLoading || starIsLoading || rubricIsLoading) return <PageLoader />;

  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumn />}>
        {!!starTopics?.length && (
          <Topic
            content={starTopics[0]}
            hideAmpLink
          >
            {content => {
              return (
                <Fragment>
                  <StarTopic content={content} rawRubric={rawRubric} />
                  <TopicFooter content={content} />
                </Fragment>
              );
            }}
          </Topic>
        )}
        {additionalTopicsIsLoading && <PageLoader />}
        {!additionalTopicsIsLoading &&
          <TopicsTaggedWithStar
            isLoading={additionalTopicsIsLoading}
            rawTopics={additionalTopics}
            moreTopics={moreAdditionalTopics}
            handlePage={handleAdditionalTopics}
            topicsPage={topicsPage}
          />
        }
      </ColumnLayout>
      <VerticalIndent />
      <Boroda />
    </PageIndent>
  );
}

Star.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};

export default Star;
