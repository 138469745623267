import PropTypes from 'prop-types';
import useRequest from 'core/hooks/useRequest';

import { Mobile } from 'core/components/breakpoint';
import Page from 'core/components/Page';
import Feed from 'core/components/Feed';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import horoscopeQuery from 'site/queries/horoscope';

import Divider from 'site/components/Divider';
import InnerSideColumn from 'site/components/InnerSideColumn';
import { PartnersHorizontal } from 'site/components/Partners';
import SectionTitle from 'site/components/SectionTitle';
import StarsBirthday from 'site/components/StarsBirthday';
import Skeleton from 'site/pages/rubric/Skeleton';

import { Card8Type1 } from 'site/cards/Card8';

import { VerticalIndent } from 'site/components/Indents';


function HoroscopePage({ title, history }) {
  const { data: content, isLoading } = useRequest(horoscopeQuery({ card: Card8Type1, history }));

  if (isLoading) return <Skeleton />;

  return (
    <Page
      title={title}
      description={`${title} - WMJ.RU`}
    >
      <PageIndent>
        <ColumnLayout rightColumn={<InnerSideColumn />}>
          <SectionTitle data-qa='page-title' htmlTag='h1'>{title}</SectionTitle>
          <VerticalIndent />
          <Divider />
          <VerticalIndent />
          <Feed
            content={content}
            interitemSpacing={28}
            card={Card8Type1}
            columns={2}
            grid
          />
          <VerticalIndent />
          <Mobile>
            <StarsBirthday />
            <VerticalIndent />
          </Mobile>
          <PartnersHorizontal />
          <VerticalIndent />
        </ColumnLayout>
      </PageIndent>
    </Page>
  );
}

HoroscopePage.propTypes = {
  title: PropTypes.string,
  history: PropTypes.object,
};

HoroscopePage.defaultProps = {
  title: 'Общий гороскоп для мужчин и женщин всех знаков зодиака на сегодня и завтра',
};

export default HoroscopePage;
