import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import rubricByType from 'site/queries/rubricByType';

import ContentLayout from 'site/components/ContentLayout';
import RubricPageByType from 'site/components/RubricPageByType';

import Skeleton from 'site/pages/rubric/Skeleton';

import tags from './tags';

export default function PhotoPage({ location, match, history }) {
  const { data, isLoading } = useRequest(rubricByType({ topic_type: 'gallery', location, match, history }));

  if (isLoading) return <Skeleton />;

  return (
    <ContentLayout
      tags={tags}
      title='Фотогалереи'
      metaTitle='Фотогалереи на WMJ'
      metaDescription='Фотогалереи: звезды, события, ковровая дорожка, модные показы на WMJ'
    >
      <RubricPageByType data={data} isLoading={isLoading} />
    </ContentLayout>
  );
}

PhotoPage.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};
