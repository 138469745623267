import Heading from 'core/components/Heading';
import Feed from 'core/components/Feed';
import Button from 'core/components/Button';
import Link from 'core/components/Link';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';
import Page from 'core/components/Page';

import content from './content';

import { Card5Type1 } from 'site/cards/Card5';

import { ContentVerticalIndent } from 'site/components/Indents';
import { InnerSideColumnShort } from 'site/components/InnerSideColumn';

import DownloadButton from 'site/components/Icon/download-button';

import styles from './index.styl';


function SpecprojectsPage() {
  return (
    <Page title='Спецпроекты'>
      <PageIndent>
        <ColumnLayout rightColumn={<InnerSideColumnShort />}>
          <div className={styles.wrapper}>
            <div className={styles.header}>
              <Heading level={1}>
                Спецпроекты
              </Heading>
              <Link to='/files/Mediakit_WomanClub.pdf' target='_blank'>
                <Button type='secondary'>
                  <DownloadButton className={styles.downloadButtonIcon} />
                  Скачать медиакит
                </Button>
              </Link>
            </div>
            <ContentVerticalIndent />
            <Feed
              content={content}
              interitemSpacing={30}
              card={Card5Type1}
              columns={2}
              grid
            />
          </div>
        </ColumnLayout>
      </PageIndent>
    </Page>
  );
}

export default SpecprojectsPage;
