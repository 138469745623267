import useRequest from 'core/hooks/useRequest';
import { aboutQuery } from 'core/queries/about';

import AboutCore from 'core/components/About';

function AboutPage(routerProps) {
  const { data: content, isLoading } = useRequest(aboutQuery(routerProps));
  return (
    <AboutCore
      isLoading={isLoading}
      content={content}
      logo={<img width={229} src={require('./images/wmj_logo.png')} />}
    />
  );
}

export default AboutPage;
