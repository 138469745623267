import queryString from 'core/libs/query-string';

import { rawTopicsQuery } from 'core/queries/rubric';

import { filterRequiredParams, denormalizeData } from 'core/utils/api';

import Card5 from 'site/cards/Card5';

import { TOPICS_LIMIT } from 'site/constants';

const include = filterRequiredParams([Card5], 'include');

export default ({ location, match, history }) => {
  const { rubric } = queryString.parse(location.search);

  return {
    ...rawTopicsQuery({
      limit: TOPICS_LIMIT,
      include,
      rubric,
      location,
      match,
      history,
    }),
    select: data => ({
      content: denormalizeData(data),
      meta: data.meta,
    }),
  };
};
