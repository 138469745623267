import useRequest from 'core/hooks/useRequest';
import { processQueryResults } from 'core/utils/query';
import { topicsQuery } from 'core/queries/topics';
import { filterRequiredParams } from 'core/utils/api';

import Card1 from 'site/cards/Card1';
import Card3 from 'site/cards/Card3';
import Card4 from 'site/cards/Card4';
import Card5 from 'site/cards/Card5';
import Card6 from 'site/cards/Card6';
import CardProduct from 'site/cards/CardProduct';

import horoscopeQuery from 'site/queries/horoscope';

export default ({ isMobile, history }) => {
  return processQueryResults(useRequest({
    queries: [
      // mainTopics
      topicsQuery({
        list: 'main',
        sort: 'list',
        limit: 4,
        fields: filterRequiredParams([Card1, Card3, Card4], 'fields'),
        include: filterRequiredParams([Card1, Card3, Card4], 'include'),
        visibility: 'main_page',
        history,
        renderError: () => [],
      }),
      // wishlist
      topicsQuery({
        card: CardProduct,
        topic_type: 'product',
        limit: 5,
        visibility: 'main_page',
        history,
        renderError: () => [],
      }),
      // news
      topicsQuery({
        card: isMobile ? Card3 : Card4,
        topic_type: 'news',
        sort: '-published_at',
        limit: 6,
        visibility: 'main_page',
        history,
        renderError: () => [],
      }),
      // videoTopics
      topicsQuery({
        card: Card5,
        topic_type: 'video',
        sort: '-published_at',
        limit: 5,
        visibility: 'main_page',
        renderError: () => [],
      }),
      // galleryTopics
      topicsQuery({
        card: Card5,
        list: 'photo',
        sort: 'list',
        limit: 5,
        visibility: 'main_page',
        renderError: () => [],
      }),
      // horoscopeTopics
      horoscopeQuery({
        include: 'horoscope',
        visibility: 'main_page',
        renderError: () => [],
      }),
      // trends
      topicsQuery({
        card: isMobile ? Card4 : Card6,
        list: 'trends',
        sort: 'list',
        limit: 8,
        visibility: 'main_page',
        renderError: () => [],
      }),
      // firstColumnTopics
      topicsQuery({
        list: 'column_1',
        sort: 'list',
        limit: 4,
        card: isMobile ? Card3 : Card4,
        visibility: 'main_page',
        renderError: () => [],
      }),
      // secondColumnTopics
      topicsQuery({
        list: 'column_2',
        sort: 'list',
        limit: 1,
        card: isMobile ? Card3 : Card4,
        visibility: 'main_page',
        renderError: () => [],
      }),
    ],
  }));
};
