import PropTypes from 'prop-types';

import bindProps from 'core/components/bindProps';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import PopularCore from 'core/components/Popular';

import { filterRequiredParams } from 'core/utils/api';

import SectionTitle from 'site/components/SectionTitle';

import Card5 from 'site/cards/Card5';
import Card3, { Card3Type3, Card3Type4 } from 'site/cards/Card3';


const PopularCard = bindProps({ imageMaxWidth: 270 })(Card5);
const HeaderComponent = bindProps({ align: 'center' })(SectionTitle);

function Popular({ topics, isDesktop }) {
  return (
    <PopularCore
      topics={topics}
      title='Популярное'
      interitemSpacing={isDesktop ? 30 : 15}
      headerComponent={HeaderComponent}
      heroCard={isDesktop ? PopularCard : Card3Type3}
      card={isDesktop ? PopularCard : Card3Type4}
      columns={4}
      dataQa='popular'
      fields={filterRequiredParams([isDesktop ? Card5 : Card3], 'fields')}
      include={filterRequiredParams([isDesktop ? Card5 : Card3], 'include')}
      sort='-views_count'
      limit={12}
      visibility='main_page'
    />
  );
}

Popular.propTypes = {
  topics: PropTypes.array,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(Popular);
