import { get } from 'core/libs/lodash';

import resolveRelationships from 'core/utils/relationships';

const relationships = resolveRelationships(['content'], {});

/**
 * Если в топике первый виджет - мини-галерея, ее ставить вместо обложки.
 *
 * https://jira.rambler-co.ru/browse/UCMS-2243
 */

// eslint-disable-next-line
export function coverAsGallery(content) {
  if (!content) return false;

  const {
    content: {
      widgets,
    },
  } = relationships(content);

  return get(widgets, '[0].type') === 'photoGallery';
}


/**
 * Нарезает массив на группы.
 * Если вторым параметром передано число, исходный массив будет нарезан
 * на равные части.
 *
 * @param {*[]} items - Исходный массив.
 * @param {number[]|number} groupSizes - Массив с количеством элементов в каждой группе.
 * @returns - массив из массивов, представляющих собой части исходного массива.
 */
export function splitInGroups(items, groupSizes) {
  if (typeof groupSizes === 'number') {
    return items.reduce((result, item, i) => {
      if (!(i % groupSizes)) result.push(items.slice(i, i + groupSizes));
      return result;
    }, []);
  }

  let count = 0;
  return [].concat(groupSizes).map(amount => {
    return items.slice(count, count += amount); // eslint-disable-line
  });
}


/**
 * Возвращает количество полных лет между датами.
 * @param {Date} date1
 * @param {Date} date2
 */
export function getFullYearsDelta(date1, date2) {
  const yearsDelta = date2.getFullYear() - date1.getFullYear();
  const monthsDelta = date2.getMonth() - date1.getMonth();
  if (monthsDelta < 0) return yearsDelta - 1;
  if (monthsDelta > 0) return yearsDelta;
  const daysDelta = date2.getDate() - date1.getDate();
  return daysDelta < 0
    ? yearsDelta - 1
    : yearsDelta;
}
