import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import rubricByType from 'site/queries/rubricByType';

import ContentLayout from 'site/components/ContentLayout';
import RubricPageByType from 'site/components/RubricPageByType';

import PageLoader from 'core/components/Loader/PageLoader';

import tags from './tags';


function NewsPage({ location, match, history }) {
  const { data, isLoading } = useRequest(rubricByType({ topic_type: 'news', location, match, history }));


  return (
    <ContentLayout
      tags={tags}
      title='Новости'
      metaTitle='Женские новости на WMJ'
      metaDescription='Женские новости: красота, мода, здоровье, любовь и секс на WMJ'
    >
      {isLoading && <PageLoader />}
      {!isLoading && <RubricPageByType data={data} isLoading={isLoading} />}
    </ContentLayout>
  );
}

NewsPage.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};

export default NewsPage;
