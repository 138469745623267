import { Fragment } from 'react';
import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { tagQuery, topicsQuery } from 'core/queries/tagTopics';

import bindProps from 'core/components/bindProps';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import replaceCardWithAd from 'core/components/Ad/hoc/replaceCardWithAd';

import Feed from 'core/components/Feed';
import TagTopics from 'core/components/TagTopics';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';
import { Mobile, Desktop } from 'core/components/breakpoint';
import PageLoader from 'core/components/Loader/PageLoader';
import H5 from 'core/components/H5';

import { filterRequiredParams } from 'core/utils/api';

import {
  VerticalIndent,
  ContentVerticalIndent,
  NegativeMobileSideIndent,
} from 'site/components/Indents';

import SectionTitle from 'site/components/SectionTitle';
import Boroda from 'site/components/Boroda';
import InnerSideColumn from 'site/components/InnerSideColumn';

import { splitInGroups } from 'site/utils';

import Card5 from 'site/cards/Card5';
import { Card3Type1 } from 'site/cards/Card3';

import { TOPIC_GROUPS, TOPICS_LIMIT } from 'site/constants';

import {
  Native1Card5,
  Native2Card5,
  Context,
  SuperFooter,
} from '../components/Ads/desktop';

import {
  Listing1,
  Listing2,
  ListingSpec,
} from '../components/Ads/mobile';


const Card5Custom = bindProps({ imageMaxWidth: 394 })(Card5);

function TagPage({ isDesktop, ...routerProps }) {
  const [
    { data: tag, isLoading: tagIsLoading },
    { data: tagTopics, isLoading: tagTopicsIsLoading },
  ] = useRequest({
    queries: [
      tagQuery(routerProps),
      topicsQuery({
        limit: TOPICS_LIMIT,
        include: filterRequiredParams([Card5], 'include'),
        fields: filterRequiredParams([Card5], 'fields'),
        ...routerProps,
      }),
    ],
  });

  const commonFeedProps = {
    card: isDesktop ? Card5Custom : Card3Type1,
    interitemSpacing: 30,
    columns: 2,
    grid: true,
  };

  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumn />}>
        <TagTopics
          tag={tag}
          topics={tagTopics}
          limit={TOPICS_LIMIT}
          titleComponent={({ children }) => (
            <SectionTitle htmlTag='h1'>{children}</SectionTitle>
          )}
          isLoading={tagIsLoading || tagTopicsIsLoading}
        >
          {({ topics }) => {
            if (tagTopicsIsLoading) return <PageLoader />;
            if (!topics?.length) return <H5>Материалов по теме не найдено</H5>;

            const [
              first,
              second,
              third,
              fourth,
            ] = splitInGroups(topics, TOPIC_GROUPS);

            return (
              <Fragment>
                <ContentVerticalIndent />
                <Feed
                  {...commonFeedProps}
                  content={first}
                  cardsReplacement={{
                    2: replaceCardWithAd(Native1Card5, isDesktop),
                  }}
                />
                <VerticalIndent />
                <Desktop>
                  <SuperFooter />
                  <VerticalIndent />
                </Desktop>
                <Mobile>
                  <NegativeMobileSideIndent>
                    <Listing1 />
                  </NegativeMobileSideIndent>
                  <VerticalIndent />
                </Mobile>
                <Feed
                  {...commonFeedProps}
                  content={second}
                />
                <VerticalIndent />
                <Mobile>
                  <ListingSpec />
                  <VerticalIndent />
                </Mobile>
                <Feed
                  {...commonFeedProps}
                  content={third}
                  cardsReplacement={{
                    2: replaceCardWithAd(Native2Card5, isDesktop),
                  }}
                />
                <VerticalIndent />
                <Mobile>
                  <NegativeMobileSideIndent>
                    <Listing2 />
                  </NegativeMobileSideIndent>
                  <VerticalIndent />
                </Mobile>
                <Feed
                  {...commonFeedProps}
                  content={fourth}
                />
                <VerticalIndent />
                <Desktop>
                  <Context />
                  <VerticalIndent />
                </Desktop>
              </Fragment>
            );
          }}
        </TagTopics>
      </ColumnLayout>
      <VerticalIndent />
      <Boroda />
      <VerticalIndent />
    </PageIndent>
  );
}

TagPage.propTypes = {
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(TagPage);
