import { Fragment } from 'react';
import PropTypes from 'prop-types';

import bindProps from 'core/components/bindProps';
import ListPage from 'core/components/ListPage';
import Feed from 'core/components/Feed';
import { withBreakpoint, Mobile, Desktop } from 'core/components/breakpoint';
import PageLoader from 'core/components/Loader/PageLoader';

import { VerticalIndent } from 'site/components/Indents';

import { splitInGroups } from 'site/utils';

import { Card3Type1 } from 'site/cards/Card3';
import Card5 from 'site/cards/Card5';

import {
  Context,
  SuperFooter,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  Listing2,
  ListingSpec,
} from 'site/components/Ads/mobile';

import { TOPIC_GROUPS, TOPICS_LIMIT } from 'site/constants';

const RubricCard = bindProps({ imageMaxWidth: 394 })(Card5);

function RubricPageByType({ data, isDesktop, isLoading }) {
  const commonFeedProps = {
    card: isDesktop ? RubricCard : Card3Type1,
    interitemSpacing: 30,
    columns: 2,
    grid: true,
  };

  return (
    <ListPage
      content={data.content}
      meta={data.meta}
      limit={TOPICS_LIMIT}
      isLoading={isLoading}
    >
      {({ content = [] }) => {
        if (isLoading) return <PageLoader />;
        if (!content?.length) return null;

        const [
          first,
          second,
          third,
          fourth,
        ] = splitInGroups(content, TOPIC_GROUPS);


        return (
          <Fragment>
            <Feed
              {...commonFeedProps}
              content={first}
            />
            <VerticalIndent />
            <Desktop>
              <SuperFooter />
              <VerticalIndent />
            </Desktop>
            <Mobile>
              <Listing1 />
              <VerticalIndent />
            </Mobile>
            <Feed
              {...commonFeedProps}
              content={second}
            />
            <VerticalIndent />
            <Mobile>
              <ListingSpec />
              <VerticalIndent />
            </Mobile>
            <Feed
              {...commonFeedProps}
              content={third}
            />
            <VerticalIndent />
            <Mobile>
              <Listing2 />
              <VerticalIndent />
            </Mobile>
            <Feed
              {...commonFeedProps}
              content={fourth}
            />
            <VerticalIndent />
            <Desktop>
              <Context />
              <VerticalIndent />
            </Desktop>
          </Fragment>
        );
      }}
    </ListPage>
  );
}

RubricPageByType.propTypes = {
  isDesktop: PropTypes.bool,
  isLoading: PropTypes.bool,
  data: PropTypes.shape({
    content: PropTypes.array,
    meta: PropTypes.object,
  }),
};

export default withBreakpoint(RubricPageByType);
